import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Badges from '../components/Badges';

const Story = () => (
  <Layout>
    <SEO
      title="Shophilist App | Story"
      keywords={[
        `shophilist`,
        `mobile app`,
        `app`,
        `shopping list`,
        `groceries`,
        `story`
      ]}
    />
    <Content>
      <Wrapper>
        <Link to="/">← Front page</Link>
        <Header>
          <H2>Why I made Shophilist?</H2>
        </Header>
        <Text>
          I made Shophilist for only one reason. I wanted to make the easiest
          shopping list possible without any clutter which can be shared with
          people in the same house. I used to manage all shopping needs in
          Whatsapp or Telegram and the lists tended to scatter among the daily
          conversations. It was daily struggle for me which could be avoided
          with better tools. Who doesn't want their daily life to be a little
          bit easier?
        </Text>
        <Text>
          In April 2017 I started building Shophilist for both Android and iOS.
          I wanted to keep it simple so I've been adding features really slowly
          after lots of questioning will it really improve the app or not. In
          example I added push notifications in 2018.
        </Text>
        <Text>
          Since April 2017 I've been using Shophilist almost daily and it has
          really made my shopping experience a lot easier. Exactly what I
          intended to solve.
        </Text>
        <Text>The app got its way to the stores in 18th March 2019.</Text>
        <Text>
          - <a href="https://twitter.com/jruokangas">@jruokangas</a>
        </Text>
        <Text>
          <Link to="/">← Front page</Link>
        </Text>
        <Badges />
      </Wrapper>
    </Content>
  </Layout>
);

const Content = styled.div`
  background-color: #fff6b9;
  width: 100vw;
  margin: 0 auto;
  flex: 1;
`;

const Wrapper = styled.div`
  background-color: #fff6b9;
  padding: 0 2rem;
  padding-top: 3rem;
  padding-bottom: 1rem;
  max-width: 34rem;
  margin: 0 auto;
  color: #607d8b;
`;

const Header = styled.header``;
const Text = styled.p``;
const H2 = styled.h2``;

export default Story;
